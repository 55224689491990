<template>
  <div class="cert_list">
    <!-- first -->
    <div class="first" v-for="(item,index) of certificate" :key="index">
      <div class="img-box">
        <el-image
          :src="item.image"
          fit="cover"
          style="width: 100%; height: 100%"
        >
          <div slot="error" class="image-slot">
            <el-image
              src="https://i.jci.cc/jci/uploads/2022/03/03/rFsCNAOf3EarA7UZKYzh19sU5hy1XvtcGy0V3NcB.png"
              fit="cover"
              style="width: 100%; height: 100%"
            >
            </el-image>
          </div>
        </el-image>
      </div>
      <div class="between_">
        <div class="cert-info">
          <h3>{{ item.title }}</h3>
          <span>{{ item.introduction }}</span>
        </div>
        <i v-show="item.digital_own" class="ri-checkbox-circle-line"></i>
      </div>
    </div>

    <!-- second -->
    <!-- <div class="second" v-if="certificate.blockchain_certificate">
      <div class="img-box">
        <el-image
          :src="certificate.blockchain_certificate_template"
          fit="cover"
          style="width: 100%; height: 100%"
        ></el-image>
      </div>
      <div class="between_">
        <div class="cert-info">
          <h3>
            Top Ten Youth Certificate
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
            >
              <path fill="none" d="M0 0H24V24H0z" />
              <path
                d="M12 1l8.217 1.826c.457.102.783.507.783.976v9.987c0 2.006-1.003 3.88-2.672 4.992L12 23l-6.328-4.219C4.002 17.668 3 15.795 3 13.79V3.802c0-.469.326-.874.783-.976L12 1zm0 2.049L5 4.604v9.185c0 1.337.668 2.586 1.781 3.328L12 20.597l5.219-3.48C18.332 16.375 19 15.127 19 13.79V4.604L12 3.05zm4.452 5.173l1.415 1.414L11.503 16 7.26 11.757l1.414-1.414 2.828 2.828 4.95-4.95z"
                fill="rgba(255,190,113,1)"
              />
            </svg>
          </h3>
          <span>You can buy a blockchain certificate</span>
          <div class="price">
            ${{ certificate.blockchain_certificate_price }}
          </div>
        </div>

        <div class="test-btn">
          <b-button
            v-show="!certificate.blockchain_own && certificate.digital_own"
            style="width: 90px"
            class="mt-4"
            variant="primary"
            @click="programPayPackage"
          >
            Pay
          </b-button>
          <i
            v-show="certificate.blockchain_own"
            class="ri-checkbox-circle-line"
          ></i>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
  props: ['certificate'],


  computed: {

  },

  components: {},

  created () { },

  methods: {
    // 支付
    programPayPackage () {
      this.$http.programPayPackage({
        program_id: this.$route.query.id,
        type: 2,
        time_zone_name:Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).then(res => {
        if (res.data.is_jump) {
          this.$router.push({
            path: '/PayMoney',
            query: {
              model_type: 6,
              id: res.data.id,
            },
          })
        } else {
          // 刷新數據
          this.$emit('updateCertifyData', true)
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 600px){

  .cert_list {
    .img-box {
      width: 148px;
      height: 90px;
    }
    .between_ {
      width: calc(100% - 148px);
      .cert-info {
        h3 {
          font-size: 16px;
        }
      }
    }
  }
}
@media (max-width: 600px){
  .cert_list {
    padding: 10px;
    .img-box {
      width: 100px;
      height: 60px;
    }
    .between_ {
      width: calc(100% - 100px);
      .cert-info {

        h3 {
          font-size: 12px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          display: -webkit-box;
        }
        >span{
          font-size: 10px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          display: -webkit-box;
        }
      }
    }
  }
}
.cert_list {
  > div {
    padding: 20px;
    display: flex;
    border-bottom: 1px solid #f1f1f1;
    .img-box {
      border: 1px solid #ddd;
      border-radius: 6px;
      overflow: hidden;
      > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .between_ {
      display: flex;
      justify-content: space-between;
      .cert-info {
        padding-left: 10px;
        h3 {
          color: #333;
          font-weight: 500;
        }

        > span {
          font-size: 12px;
          color: #999999;
        }

        .price {
          font-size: 16px;
          color: #333;
          font-weight: 600;
          margin-top: 20px;
        }
      }

      > i {
        font-size: 20px;
        color: #57b8ff;
      }
    }

    .test-btn {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      > i {
        font-size: 20px;
        color: #57b8ff;
      }
    }
  }
}
</style>
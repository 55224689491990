<template>
  <div class="user_search_list">
    <el-autocomplete
      style="width: 100%"
      popper-class="my-autocomplete"
      v-model="state"
      :fetch-suggestions="querySearch"
      placeholder="Search Member"
      @select="handleSelect"
      :disabled="disabled"
    >
      <i class="el-icon-edit el-input__icon" slot="suffix"> </i>
      <template slot-scope="{ item }">
        <el-row>
          <el-col :span="6">
            <div style="display: flex" class="flex">
              <div style="margin-top: 6px; margin-right: 10px">
                <el-avatar :src="item.avatar"></el-avatar>
              </div>
              <div
                :title="item.nick_name"
                style="
                  line-height: 52px;
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ item.nick_name }}
              </div>
            </div>
          </el-col>
          <el-col :title="item.email" :span="8">
            <span style="display:inline-block;
              line-height: 52px;
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;">{{ item.email | filterNone }}</span>
          </el-col>
          <el-col :span="7">
            <span :title="item.organization_name" style="
              display:inline-block;
              line-height: 52px;
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;">{{
              item.organization_name | filterNone
            }}</span>
          </el-col>
          <el-col :span="3">
            <span :title="item.country" style="display:inline-block;
              line-height: 52px;
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;">{{
              item.country | filterNone
            }}</span>
          </el-col>
        </el-row>
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
export default {
  data () {
    return {
      state: ''
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,

    }
  },
  filters: {
    filterNone (val) {
      return val ? val : '-'
    },
  },

  methods: {
    async querySearch (keyword, cb) {
      // 根据字符请求
      let res = await this.$http.searchUserList({
        keyword,
      })
      if (res.status == 200) {
        let userList = res.data;
        // var results = keyword ? userList.filter(this.createFilter(keyword)) : userList;
        cb(userList);
      }
    },


    createFilter (keyword) {
      return (restaurant) => {
        return (restaurant.nick_name.toLowerCase().indexOf(keyword.toLowerCase()) === 0 || restaurant.email.toLowerCase().indexOf(keyword.toLowerCase()) === 0);
      };
    },

    // 选择
    handleSelect (item) {
      this.$emit('userItem', item)
    },

    async getSearchList () {
      let res = await this.$http.searchUserList({
        keyword: this.keyword,
      })
      if (res.status == 200) {
        this.searchList = res.data;
      }
    }
  },
  mounted () {

  }
}


</script>
<style lang="scss" scoped>
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .user_item {
      display: flex;
      // justify-content: center;
      align-items: center;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

// ::v-deep .el-input__inner {
//   background: #f5f7fa !important;
// }
</style>